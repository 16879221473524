import { defineComponent } from 'vue';
import BaseIcon from './BaseIcon.vue';
export default defineComponent({
    name: 'BaseButton',
    components: { BaseIcon },
    props: {
        look: {
            type: String,
            validator: (value) => value === 'reversed',
        },
        iconName: {
            type: String,
            validator: (value) => value === 'arrowRight' ||
                value === 'play' ||
                value === 'arrowLeft',
        },
        href: String,
        target: String,
    },
});
