import confetti from 'canvas-confetti';
export const triggerConfetti = (canvas) => {
    if (!canvas.value)
        return;
    const randomInRange = (min, max) => Math.random() * (max - min) + min;
    const confettiCannon = confetti.create(canvas.value, {
        resize: true,
        disableForReducedMotion: true,
    });
    for (let i = 0; i < 3; i++)
        setTimeout(() => {
            confettiCannon({
                angle: [135, 45, 90][i],
                spread: randomInRange(50, 70),
                particleCount: randomInRange(50, 100),
                origin: { y: 0.75 },
            });
        }, (i + 1) * 300);
};
